const FontFaceObserver = require('fontfaceobserver');

export default function signalWhenFontLoaded(fontFamily, loadedClass) {
  const regularFFO = new FontFaceObserver(fontFamily);
  const boldFFO = new FontFaceObserver(fontFamily, { weight: 'bold' });

  return Promise.all([regularFFO.load(), boldFFO.load()])
    .then(() => {
      document.documentElement.className += ` ${loadedClass}`;
    })
    .catch((error) => {
      console.error(`Error loading ${fontFamily} font: ${error}`);
    });
}
